import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import BrokerCard from "../components/Brokers/BrokerCard"
import searchIcon from "../assets/images/search-icon.svg"
import axios from "axios"
import SEO from "../components/App/SEO"
import { Typeahead } from "react-bootstrap-typeahead"

const Brokers = () => {
  const [entity, setEntity] = useState([])
  const [broker, setBroker] = useState({})
  const [searchQuery, setSearchQuery] = useState("")
  // const [searchedQuery, setSearchedQuery] = useState("default")
  const [suburbSelected, setSuburbSelected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState([])

  const stateRef = useRef()
  stateRef.current = searchQuery
  function throttle(callback, throttleTime) {
    function clear() {
      clearTimeout(timeout)
    }
    var timeout = setTimeout(callback, throttleTime)
    throttle.clearTimeout = function () {
      clear()
    }
  }
  useEffect(() => {
    if (stateRef.current && !suburbSelected) {
      throttle(handleQueryUpdate, 300)
    }
  }, [searchQuery])

  useEffect(async () => {
    var postcode = new URL(window.location.href).searchParams.get("postcode")
    if (postcode) {
      try {
        setSuburbSelected(true)
        setLoading(true)
        const results = await axios.get(
          `${process.env.GATSBY_BACKEND_API_URL}/brokers/suburbs/${postcode}`
        )
        // setEntity([]) // false //check
        setSelectedOption([postcode])
        setLoading(false)
        setSearchQuery(postcode)
        setBroker(results.data)
        if (entity.length == 0) {
          setEntity([{ value: postcode }])
        }
      } catch (error) {
        setLoading(false)
      }
    }
  }, [])

  function handleChange(query) {
    setSuburbSelected(false)
    setSearchQuery(query)
    setSelectedOption([query])
  }

  function handleChangeBlur(query) {
    // setSuburbSelected(false)
    if (query.target.value) {
      setSearchQuery(query.target.value)
      setSelectedOption([query.target.value])
    }
    // setEntity()
  }

  async function handleQueryUpdate() {
    setBroker([])
    // setSearchedQuery(() => stateRef.current)
    try {
      setLoading(true)
      const results = await axios.get(
        `${process.env.GATSBY_BACKEND_API_URL}/brokers?filterValue=${stateRef.current}`
      )
      setLoading(false)
      setEntity(results.data)
    } catch (error) {
      setLoading(false)
    }
    // }
  }

  async function handleSuburbSelection(suburb, code) {
    // if (selectedEntity.type === "suburb") {
    try {
      // setSearchQuery(event.target.value)
      setSuburbSelected(true)
      setLoading(true)
      const results = await axios.get(
        `${process.env.GATSBY_BACKEND_API_URL}/brokers/suburbs/${code}`
      )
      // setEntity([])   /////////check
      setLoading(false)
      setSearchQuery(suburb)

      // setSuburbSelected(true)
      setBroker(results.data)
      var replaceUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?postcode" +
        "=" +
        suburb
      window.history.pushState({ path: replaceUrl }, "", replaceUrl)
    } catch (error) {
      setLoading(false)
    }
    // }
  }

  function setSingleSelections(selection) {
    console.log("selection", selection)
    if (selection.length > 0) {
      if (selection[0].type == "suburb") {
        handleSuburbSelection(selection[0].value, selection[0].code)
        setSelectedOption([selection[0].value])
      } else {
        navigate("/broker-details/?broker=" + selection[0].id)
      }
    }
  }

  return (
    <Layout>
      <SEO
        title="Find a Broker | Oxygen - Home Loans Made Simple"
        description="Connect to a broker anywhere in Australia."
      />
      <Navbar />
      <div className="brokers-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Find a Broker</h2>
            <h3>Connect to a broker anywhere in Australia.</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="broker-search-area">
          <form className="broker-search-form">
            <h5 className="screen-reader-text">Search for:</h5>
            <div className="input-group search-field">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="bx bx-search-alt"></i>
                </span>
              </div>
              <Typeahead
                className="form-control"
                id="basic-typeahead-single"
                labelKey="value"
                onChange={setSingleSelections}
                options={entity}
                placeholder="Enter a Broker Name, Suburb or Postcode"
                selected={selectedOption}
                onInputChange={handleChange}
                minLength="1"
                onFocus={handleChangeBlur}
                emptyLabel={
                  "No Brokers found for " +
                  searchQuery +
                  ". Please enter a Broker Name, Suburb or Postcode."
                }
              />
            </div>
            {loading ? <div className="loader"></div> : null}
          </form>
        </div>
      </div>
      <div className="container broker-search-results my-5">
        {suburbSelected ? (
          <div className="row">
            {broker.length > 0
              ? broker.map(broker => (
                  <BrokerCard key={broker.id} broker={broker} />
                ))
              : null}
          </div>
        ) : null}

        {!searchQuery ? (
          <div className="placeholder">
            <img src={searchIcon} alt="Search Broker" />
            <p className="mt-4">
              <span className="highlight">Oxygen</span> gives you access to many
              experienced brokers from all across Australia!
            </p>
          </div>
        ) : null}
      </div>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Brokers
