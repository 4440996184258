import React from "react"
import { Link } from "gatsby"
import callIcon from "../../assets/images/call-icon.svg"
import messageIcon from "../../assets/images/message-icon.svg"

const BrokerCard = props => {
  return (
    <div className="col-lg-3 col-sm-6 col-md-4 mb-4">
      <Link to={`/broker-details/?broker=${props.broker.id}`}>
        <div className="single-broker-box">
          <div className="image">
            {/* <Link to="/broker-details" className="d-block"> */}
            <img src={props.broker.brokerImage} alt="event" />
            {/* </Link> */}
            {/* <span className="date">Tue, 19 May, 2020</span> */}
          </div>

          <div className="content">
            <h3>
              {/* <Link to={`/broker-details?broker=${props.broker.id}`}> */}
              {props.broker.firstLastName}
              {/* </Link> */}
            </h3>
            {/* <span className="location">
              {props.broker.specialty}
            </span> */}
            <div className="actions">
              <Link
                to={`/book-a-call?broker=${props.broker.id}`}
                className="broker-action call"
              >
                <img src={callIcon} alt="Call Broker" />
                <span>Schedule a Call</span>
              </Link>
              <Link
                to={`/contact?broker=${props.broker.id}`}
                className="broker-action message"
              >
                <img src={messageIcon} alt="Message Broker" />
                <span>Send a Message</span>
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BrokerCard
